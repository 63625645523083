.cta {
	display: flex;
	align-items: center;
	flex-direction: column;

	.cta-text {
		letter-spacing: .2rem;
		text-align: center;
		text-transform: uppercase;
	}
}

.cta {
	@include media-breakpoint-down(sm) {
		.cta-text {
			font-size: rem(14px);
			letter-spacing: .15rem;
		}

		.btn {
			display: block;
			width: 100%;
		}
	}
}
