// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	margin-bottom: $headings-margin-bottom;
	color: $headings-color;
}

h1, .h1 {
	@include font-size($h1-font-size); // 40px !default
}
h2, .h2 {
	@include font-size($h2-font-size); // 32px !default
}
h3, .h3 {
	@include font-size($h3-font-size); // 28px !default
}
h4, .h4 {
	@include font-size($h4-font-size); // 24px !default
}
h5, .h5 {
	@include font-size($h5-font-size); // 20px !default
}
h6, .h6 {
	@include font-size($h6-font-size); // 16px !default
}

.fs1 {
	@include font-size($h1-font-size);
}
.fs2 {
	@include font-size($h2-font-size);
}
.fs3 {
	@include font-size($h3-font-size);
}
.fs4 {
	@include font-size($h4-font-size);
}
.fs5 {
	@include font-size($h5-font-size);
}
.fs6 {
	@include font-size($h6-font-size);
}

.lead {
	font-weight: $lead-font-weight;

	@include font-size($lead-font-size);
}

// Type display classes
.display-1 {
	font-weight: $display1-weight;
	line-height: $display-line-height;

	@include font-size($display1-size);
}
.display-2 {
	font-weight: $display2-weight;
	line-height: $display-line-height;

	@include font-size($display2-size);
}
.display-3 {
	font-weight: $display3-weight;
	line-height: $display-line-height;

	@include font-size($display3-size);
}
.display-4 {
	font-weight: $display4-weight;
	line-height: $display-line-height;

	@include font-size($display4-size);
}


//
// Horizontal rules
//

hr {
	margin-top: $hr-margin-y;
	margin-bottom: $hr-margin-y;
	border: 0;
	border-top: $hr-border-width solid $hr-border-color;
}


//
// Emphasis
//

small,
.small {
	font-weight: $font-weight-normal;

	@include font-size($small-font-size);
}

mark,
.mark {
	padding: $mark-padding;
	background-color: $mark-bg;
}


//
// Lists
//

.list-unstyled {
	@include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
	@include list-unstyled;
}
.list-inline-item {
	display: inline-block;

	&:not(:last-child) {
		margin-right: $list-inline-padding;
	}
}


//
// Misc
//

// Builds on `abbr`
.initialism {
	text-transform: uppercase;

	@include font-size(90%);
}

// Blockquotes
.blockquote {
	margin-bottom: $spacer;

	@include font-size($blockquote-font-size);
}

.blockquote-footer {
	display: block;
	color: $blockquote-small-color;

	@include font-size($blockquote-small-font-size);

	&::before {
		content: '\2014\00A0'; // em dash, nbsp
	}
}


.hr-sm {
	max-width: 20px;
	border-width: 4px;
}

.hr-green {
	border-color: $green;
}

.text-blue-400 {
	color: $blue-400;
}