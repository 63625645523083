.entry-header {
	padding: 2.5rem 0;
	background-color: $blue-gray-100;
}
.entry-title {
	font-size: rem(35px);
	margin-bottom: 4rem;
	text-align: center;
}
.entry-content {
	padding-top: 1px;
	padding-bottom: 2.5rem;
	background-color: #f6f6f6;
	background-image: url(../images/background/page-01.jpg);
	background-repeat: repeat-x;
	background-position: 50% 0;

	.content-wrap {
		margin-top: -2.5rem;
		padding: 2.5rem;
		background-color: #fff;
		box-shadow: $box-shadow;
	}
}

.entry-content {
	ul {
		padding-left: 1.5rem;
	}
	ul li {
		margin-bottom: 1rem;
	}

	.banner-heading {
		font-size: rem(34px);
		text-align: center;
	}
}

@include media-breakpoint-down(md) {
	.entry-title {
		font-size: rem(24px);
		margin-bottom: 1rem
	}

	.entry-content {
		.content-wrap {
			padding: 1.25rem;
		}

		.banner-heading {
			font-size: rem(24px);
		}
	}
}

@import 'home';
