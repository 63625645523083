.site-footer {
	font-size: rem(12px);
	padding: 3rem 0;

	* + .site-info {
		margin-top: 2rem;
		padding-top: 2rem;
		border-top: 1px solid #e5ebf1;
	}

	.copyright {
		text-align: center;
		letter-spacing: .2rem;
		text-transform: uppercase;
		color: #8b98a6;
	}
}

.site-footer {
	@include media-breakpoint-down(sm) {
		padding: 1rem 0;

		* + .site-info {
			margin-top: .5rem;
			padding-top: 1rem;
		}

		.copyright {
			letter-spacing: normal;
		}
	}
}

@import 'widgets';
