.widget {
	a {
		color: inherit;
		padding: 0;

		&:hover {
			color: $link-color;
		}
	}

	.widget-title {
		text-transform: uppercase;
		font-size: rem(18px);
	}
}

.widget + .widget {
	margin-top: 1rem;
}

.widget_nav_menu {
	.nav-link {
		padding: .25rem 0;
	}
}

.widget_contact_info {
	p {
		margin-bottom: .5rem;
	}
}


.widget_social_links {
	display: flex;
	align-items: center;

	.widget-title {
		font-size: rem(12px);
		margin-bottom: 0;
	}

	.nav-link {
		font-size: 1rem;
		color: #8b98a6;
		padding: .5rem;
	}
}