.section-heading {
	font-size: rem(30px);
	margin-bottom: 2rem;
}

.heading-sm {
	font-size: 1rem;
	font-weight: 300;
	margin-bottom: .5rem;
	letter-spacing: .2rem;
	text-transform: uppercase;
}

.gn-section {
	.arrow {
		position: relative;
		z-index: 100;
	}
}

@import 'newsletter';
@import 'cta';
@import 'offers';
@import 'products';
@import 'banner';
