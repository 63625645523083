.products-carousel {
	.slick-track {
		display: flex;
		align-items: flex-end;
	}
	.product {
		flex-grow: 1;
	}
	.product-img {
		position: relative;
		z-index: 100;
		display: block;
		margin: 0 auto;
	}
	.product-btn-wrap {
		margin-top: -5rem;
		padding: 6rem 2rem 2rem 2rem;
		background-color: #fff;
	}
	.product.slick-current {
		.product-btn-wrap {
			position: relative;
			// box-shadow: $box-shadow;
		}

		.product-btn {
			border-color: $yellow;
			background-color: $yellow;

			&:not(:hover) {
				box-shadow: 0 .5rem 1rem rgba($yellow, .5);
			}

			&:focus,
			&:not(:disabled):not(.disabled):active {
				box-shadow: 0 0 0 $btn-focus-width rgba($yellow, .5);
			}
		}
	}

	.slick-prev, .slick-next {
		font-size: rem(10px);
		font-weight: 300;
		z-index: 100;
		top: 100%;
		width: auto;
		margin-top: 1rem;
		transform: none;
		letter-spacing: .2rem;
		text-transform: uppercase;
		color: inherit;

		&::before,
		&::after {
			display: inline-block;
			width: 48px;
			height: 22px;
			content: none;
			vertical-align: middle;
			opacity: .25;
		}

		&:hover {
			&::before,
			&::after {
				opacity: 1;
			}
		}
	}
	.slick-prev {
		left: 0;

		&::before {
			margin-right: .5rem;
			content: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 50 23\'%3E%3Cline x1=\'1\' y1=\'11\' x2=\'49\' y2=\'11\' style=\'fill: none;stroke: %23000;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px\'/%3E%3Cpolyline points=\'11 1 1 11 11 22\' style=\'fill: none;stroke: %23000;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px\'/%3E%3C/svg%3E');
		}
	}
	.slick-next {
		right: 0;

		&::after {
			margin-left: .5rem;
			content: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 50 23\'%3E%3Cline x1=\'49\' y1=\'11\' x2=\'1\' y2=\'11\' style=\'fill: none;stroke: %23000;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px\'/%3E%3Cpolyline points=\'39 1 49 11 39 22\' style=\'fill: none;stroke: %23000;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px\'/%3E%3C/svg%3E%0A');
		}
	}

	&.slick-dotted.slick-slider {
		margin-bottom: 4rem;
	}

	.slick-dots {
		top: 100%;
		bottom: auto;
		margin-top: 1rem;
	}
	.slick-dots li button::before {
		font-family: 'Font Awesome 5 Free';
		font-size: 1rem;
		font-weight: 900;
		content: '\f111';
	}
	.slick-dots li.slick-active button::before {
		font-size: 3rem;
		content: '\f068';
	}
	.slick-dots li.slick-active,
	.slick-dots li.slick-active button,
	.slick-dots li.slick-active button::before {
		width: 3rem;
	}
}


.products-carousel {
	@include media-breakpoint-down(md) {

		.slick-prev,
		.slick-next {
			&::before,
			&::after {
				width: 12px;
				height: 23px;
			}
		}
		.slick-prev {
			&::before {
				content: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 12 23\'%3E%3Cpolyline points=\'11 1 1 11 11 22\' style=\'fill: none;stroke: %23000;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px\'/%3E%3C/svg%3E%0A');
			}
		}
		.slick-next {
			&::after {
				content: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 12 23\'%3E%3Cpolyline points=\'1 1 11 11 1 22\' style=\'fill: none;stroke: %23000;stroke-linecap: round;stroke-linejoin: round;stroke-width: 2px\'/%3E%3C/svg%3E%0A');
			}
		}
	}
	@include media-breakpoint-up(lg) {
		.product.slick-current {
			.product-btn-wrap {
				position: relative;
				box-shadow: $box-shadow;
			}
		}
	}
}
