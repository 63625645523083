.btn {
	text-transform: uppercase;
	text-shadow: 0 1px 0 rgba(#000,.1);
}

.btn-sm {
	font-size: rem(11px);
	padding: .5rem 1.2rem;
	letter-spacing: .12rem;
}

.btn-lg {
	font-family: 'Montserrat', sans-serif;
	font-size: rem(22px);
	font-weight: 900;
	padding: .75rem;
}
.btn-lg {
	@include media-breakpoint-up(md) {
		font-size: rem(29px);
		padding: .75rem 4rem;
	}
}

.btn-yellow {
	@include button-variant($yellow, $yellow);
}
.btn-yellow {
	color: #fff;

	&:hover,
	&:focus,
	&:not(:disabled):not(.disabled):active {
		color: #fff;
	}

	&:not(:hover) {
		box-shadow: 0 .5rem 1rem rgba($yellow, .5);
	}
}

.btn-green {
	@include button-variant($green, $green);
}
.btn-green {
	color: #fff;

	&:hover,
	&:focus,
	&:not(:disabled):not(.disabled):active {
		color: #fff;
	}

	&:not(:hover) {
		box-shadow: 0 .5rem 1rem rgba($green, .5);
	}
}

.btn-outline-gray {
	color: #656565;
	border: 2px solid #cfcfcf;

	&:hover,
	&:focus,
	&:not(:disabled):not(.disabled):active {
		color: #fff;
		border-color: $dark;
		background-color: $dark;
	}
	&:focus,
	&:not(:disabled):not(.disabled):active {
		box-shadow: 0 0 0 $btn-focus-width rgba($dark, .5);
	}
}
