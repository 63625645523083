.nav-tabs {
	border-bottom: 0;

	.nav-item {
		margin-bottom: 0;
	}

	.nav-link {
		border: none;
		border-radius: 0;
	}
}

.tab-content {
	text-align: left;
}

.nav-tabs-theme1 {
	.nav-tabs {
		position: relative;
		max-width: calc(100% - 2px);
		background-color: #f6f6f6;
	}

	.nav-item {
		display: flex;
	}

	.nav-link {
		font-size: rem(14px);
		font-weight: 500;
		flex-grow: 1;
		padding: .5rem 1.75rem;
		color: inherit;

		&.active {
			position: relative;
			color: #fff;
			background-color: $blue-400;

			&:before {
				position: absolute;
				top: 100%;
				left: 50%;
				display: block;
				width: 0;
				height: 0;
				content: '';
				transform: translateX(-50%);
				border-width: 13px 14px 0 14px;
				border-style: solid;
				border-color: #45aaee transparent transparent transparent;
			}
		}

		.icon {
			display: block;
			margin-right: auto;
			margin-left: auto;
		}
	}

	.tab-content {
		padding: 2rem 3rem;
		border: 1px solid #e5ebf1;
	}

	.scrtabs-tab-container,
	.scrtabs-tabs-fixed-container,
	.scrtabs-tab-scroll-arrow {
		height: auto;
	}

	.scrtabs-tab-container {
		position: relative;
		display: flex;
		align-items: center;
		background-color: #f6f6f6;
	}

	.scrtabs-tabs-fixed-container {
		min-width: 100%;
	}

	.scrtabs-tab-scroll-arrow {
		font-size: 1rem;
		position: absolute;
		z-index: 100;
		top: 0;
		width: auto;
		height: 100%;
		margin-bottom: 0;
		padding: .5rem;
		border: 1px solid transparent;
		background-color: rgba(#f6f6f6, .8);

		&.scrtabs-disable {
			visibility: none;
			opacity: 0;
		}

		span {
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	.scrtabs-tab-scroll-arrow-left {
		left: 0;
	}

	.scrtabs-tab-scroll-arrow-right {
		right: 0;
	}
}

.nav-tabs-theme1 {
	@include media-breakpoint-down(md) {
		.nav-link.active .icon {
			filter: brightness(0) invert(1);
		}

		.tab-content {
			padding: 1rem;
		}
	}
	@include media-breakpoint-up(lg) {
		.nav-tabs {
			&::before {
				position: absolute;
				z-index: 100;
				top: 35%;
				left: 50%;
				width: calc(100% - 10rem);
				height: 4px;
				content: '';
				transform: translate(-50%, -35%);
				background-color: #fff;
			}
		}

		.nav-link {
			padding: 1rem 2rem;

			.icon {
				position: relative;
				z-index: 200;
				margin-bottom: .5rem;
				border-radius: 50%;
				background-color: #fff;
			}
		}
	}
}

.c-offcanvas {
	width: 296px;

	&.is-hidden {
		display: none;
	}

	.menu-header {
		.site-title {
			font-size: rem(14px);
			font-weight: 900;
			margin-bottom: 0;
			text-transform: uppercase;
			color: $blue-500;

			span {
				color: #00a85a;
			}
		}

		.menu-title {
			font-size: rem(18px);
			font-weight: 300;
			margin-bottom: 0;
			color: $blue-400;
		}
	}

	.navbar {
		 > div {
			flex-grow: 1;
		}
		.navbar-nav {
			margin-bottom: 2rem;
			padding-bottom: 2rem;
			padding-left: 1rem;
			list-style-type: disc;
			color: #73889d;
			border-bottom: 1px solid #e5ebf1;
		}
		.menu-title {
			font-size: rem(20px);
			font-weight: 900;
			text-transform: uppercase;
		}

		.nav-link {
			padding: 0;
			color: #73889d;
		}

		.nav-link:hover,
		.nav-item.active .nav-link {
			text-decoration: underline;
			color: #45aaee;
		}
	}
}

#mobile-menu {
	.menu-title {
		font-size: rem(20px);
		font-weight: 900;
		margin-bottom: 0;
		text-transform: uppercase;
		color: inherit;
	}
	.navbar-nav {
		border-bottom: none;
	}
}
