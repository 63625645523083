img.alignleft,
img.alignright,
img.aligncenter {
	display: block;
	margin-bottom: 1rem;
}

img.alignleft {
	float: left;
	margin-right: 1rem;
}

img.alignright {
	float: right;
	margin-left: 1rem;
}

img.aligncenter {
	clear: both;
	margin-right: auto;
	margin-left: auto;
}

@include media-breakpoint-down(sm) {
	img.alignleft,
	img.alignright {
		float: none;
		clear: both;
		margin-right: auto;
		margin-left: auto;
	}
}
