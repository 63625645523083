// Tabs height
$scrtabs-tabs-height: 42px !default;

// Border color (bootstrap lt gray)
$scrtabs-border-color: 1px solid rgb(221, 221, 221) !default;

// Foreground color (bootstrap blue)
$scrtabs-foreground-color: rgb(66, 139, 202) !default;

// Background color on hover (bootstrap gray)
$scrtabs-background-color-hover: rgb(238, 238, 238) !default;

.scrtabs-tab-container * {
	box-sizing: border-box;
}

.scrtabs-tab-container {
	height: $scrtabs-tabs-height;
	.tab-content {
		clear: left;
	}
}

.scrtabs-tab-container.scrtabs-bootstrap4 .scrtabs-tabs-movable-container > .navbar-nav {
	-ms-flex-direction: row;
	    flex-direction: row;
}

.scrtabs-tabs-fixed-container {
	float: left;
	overflow: hidden;
	width: 100%;
	height: $scrtabs-tabs-height;
}

.scrtabs-tabs-movable-container {
	position: relative;
	.tab-content {
		display: none;
	}
}

// override user agent padding-start (which translates to padding-right for
// RTL sites) of 40px applied to <ul> elements (Bootstrap already overrides
// it for LTR sites by setting padding-left to 0)
.scrtabs-tab-container.scrtabs-rtl .scrtabs-tabs-movable-container > ul.nav-tabs {
	padding-right: 0;
}

.scrtabs-tab-scroll-arrow {
	font-size: 12px;
	display: none;
	float: left;
	width: 20px;
	height: $scrtabs-tabs-height;
	margin-bottom: -1px;
	padding-top: 13px;
	padding-left: 2px;
	color: $scrtabs-foreground-color;
	border: $scrtabs-border-color;
	border-top: none;
	&:hover {
		background-color: $scrtabs-background-color-hover;
	}
}

.scrtabs-tab-scroll-arrow,
.scrtabs-tab-scroll-arrow .scrtabs-click-target {
	cursor: pointer;
}

.scrtabs-tab-scroll-arrow.scrtabs-with-click-target {
	cursor: default;
}

.scrtabs-tab-scroll-arrow.scrtabs-disable,
.scrtabs-tab-scroll-arrow.scrtabs-disable .scrtabs-click-target {
	cursor: default;
	color: #ddd;
}

.scrtabs-tab-scroll-arrow.scrtabs-disable:hover {
	background-color: initial;
}

.scrtabs-tabs-fixed-container ul.nav-tabs > li {
	white-space: nowrap;
}
