.home {
	#banner {
		text-align: center;
		color: $blue-gray-500;
		background-image: url(../images/background/banner.jpg);
		background-repeat: repeat-x;
		background-position: top;

		 > .container {
			position: relative;
		}

		.headline {
			display: block;
			max-width: 100%;
			// height: auto;
			margin: 0 auto;
		}

		.lead {
			font-size: rem(24px);
		}

		.cta {
			position: relative;

			.btn,
			.cta-text {
				position: relative;
				z-index: 100;
			}

			.btn {
				padding: .75rem 2rem;
			}

			&[data-text-1]::before,
			&[data-text-2]::after {
				font-family: 'Montserrat', sans-serif;
				font-size: rem(150px);
				font-weight: 900;
				line-height: 1;
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				text-transform: uppercase;
			}
			&[data-text-1]::before {
				content: attr(data-text-1);
				transform: translate(-50%, -104%);
				opacity: .06;
				color: #45aaee;
			}
			&[data-text-2]::after {
				content: attr(data-text-2);
				transform: translate(-50%, -33%);
				color: #f6f6f6;
			}
		}

		.cta-text {
			font-size: rem(12px);
			font-weight: 900;
			color: #656565;

			span {
				color: $blue-400;
			}
		}

		.products-img-wrap {
			position: relative;
			display: flex;
			align-items: center;
			width: 100%;
			height: 577px;
			background-image: url(../images/banner-products-bg-01.png);
			background-repeat: no-repeat;
			background-position: center top;
			background-size: contain;

			&::before {
				top: 0;
				left: 0;
				position: absolute;
				width: 100%;
				height: 100%;
				content: '';
				background-image: url(../images/banner-products-bg-02.png);
				background-repeat: no-repeat;
				background-position: center top;
				background-size: contain;
			}

			img {
				position: relative;
			}
		}

		.certified {
			font-size: rem(12px);
			display: flex;
			align-items: center;
			justify-content: center;

			 > * {
				max-width: none;
				margin: .5rem;
			}
		}
	}

	#what-we-offer {
		background-image: url(../images/background/home-02.png);
		background-repeat: repeat-x;
		background-position: center top;
	}

	#who-we-are {
		max-width: 1066px;
	}

	#start-your-brand {
		background-image: url(../images/background/home-03.png);
		background-repeat: repeat-x;
		background-position: center top;
	}
}

.home {
	#banner {
		@include media-breakpoint-down(md) {
			background-image: url(../images/background/banner-sm.jpg);

			.headline {
				max-width: 285px;
			}

			.lead {
				font-size: 1rem;
			}

			.cta {
				&[data-text-1]::before,
				&[data-text-2]::after {
					font-size: rem(70px);
				}

				&[data-text-1]::before {
					transform: translateY(-103%);
				}
				&[data-text-2]::after {
					transform: translateY(-34%);
				}

				.btn {
					font-size: rem(22px);
					width: auto;
				}
			}

			.certified {
				font-size: rem(14px);
				flex-wrap: wrap;

				p {
					flex: 1 0 100%;
				}
			}

			.products-img-wrap {
				max-width: 320px;
				height: 220px;
				margin: 0 auto;
				background-size: 100% auto;
			}
		}
	}
}
