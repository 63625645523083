.offers {
	.offer {
		font-size: rem(14px);
		display: flex;
	}

	.offer-wrap {
		display: flex;
		align-items: flex-start;
		flex-grow: 1;
		padding: 3rem;
		border: 1px solid #e5ebf1;
	}

	// .offer-text {
	// 	display: flex;
	// 	flex-direction: column;
	// 	align-self: normal;
	// }

	.offer-heading {
		font-family: 'Montserrat', sans-serif;
		font-size: rem(30px);
		font-weight: 900;
		margin-bottom: 0;
	}
	.offer-sub-heading {
		font-size: inherit;
		font-weight: inherit;
		color: $blue-gray-500;
	}
	.icon {
		flex-shrink: 0;
		margin-right: 1rem;
		margin-bottom: 1rem;
	}
}


.offers {
	@include media-breakpoint-down(xs) {
		.offer-wrap {
			align-items: center;
			flex-direction: column;
			text-align: center;
		}
	}
	@include media-breakpoint-down(lg) {
		.offer-wrap {
			padding: 2rem 1rem;
		}
		.offer + .offer {
			border-top: 1px solid #e5ebf1;
		}
	}
	@include media-breakpoint-up(xl) {
		.offer + .offer {
			border-left: 1px solid #e5ebf1;
		}
	}
}
