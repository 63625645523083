.newsletter-optin {
	padding: 3rem 0;
	text-align: center;
	color: #fff;
	background-color: $blue-500;

	.optin-title {
		font-size: rem(30px);
		margin-bottom: 0;
		text-transform: uppercase;
	}

	form {
		.form-control + .form-control,
		.btn-subscribe {
			margin-top: .5rem;
			margin-bottom: .5rem;
		}

		.form-control {
			&::placeholder {
				font-size: rem(11px);
				color: #8d8d8d;
			}

			// IE 11
			&:-ms-input-placeholder {
				height: 2.4rem;
			}

			// Edge
			&::-ms-input-placeholder {
				transform: scale(.6) translateX(-32%);
			}
		}
	}

	.btn-subscribe {
		font-size: rem(11px);
		font-weight: 400;
		padding: .62rem 2rem;
		letter-spacing: .12rem;
		text-transform: uppercase;

		@include button-variant($blue-400, $blue-400);
	}
}

.newsletter-optin {
	@include media-breakpoint-down(sm) {
		.optin-title {
			font-size: rem(22px);
		}
	}
	@include media-breakpoint-up(sm) {
		form {
			.form-control + .form-control,
			.btn-subscribe {
				margin-left: .5rem;
			}
		}
	}
}
