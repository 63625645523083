.banner-actions {
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;

	.slick-dots {
		line-height: 1;
		position: relative;
		bottom: auto;
		display: flex;
		flex-direction: column;

		li + li {
			margin-top: .5rem;
		}
	}

	.slick-dots li button::before {
		font-family: 'Font Awesome 5 Free';
		font-size: 1rem;
		font-weight: 400;
		content: '\f111';
		opacity: 1;
		color: #d7dfe8;
	}
	.slick-dots li.slick-active button::before {
		font-weight: 900;
		color: #45aaee;
	}

	.title {
		font-size: rem(12px);
		transform: rotate(90deg);
		letter-spacing: .4rem;
		text-transform: uppercase;
		color: #b9c3ce;
	}

	.social-media-links {
		.nav-link {
			color: #b9c3ce;

			&:hover {
				color: $link-color;
			}
		}
	}
}

.banner-actions {
	@include media-breakpoint-down(md) {
		margin-top: 7rem
	}
}

.content-banner {
	background-position: center;
	background-size: cover;
}

.private-label {
	.content-banner {
		background-image: url(../images/background/content-01.jpg);
	}
}

.manufacturing {
	.content-banner {
		background-image: url(../images/background/content-02.jpg);
	}
}
