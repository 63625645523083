.site-logo,
.site-branding-text {
	display: inline-block;
	vertical-align: middle;
}

.site-logo {
	margin-right: .75rem;
}

.site-branding-text {
	text-align: center;
}

.site-title,
.site-description {
	line-height: 1;
	margin-bottom: 0;
}

.site-title {
	font-size: rem(31px);
	font-weight: 900;
	text-transform: uppercase;

	span {
		font-weight: 300;
		color: #00a85a;
	}
}

.site-description {
	font-size: rem(12px);
	letter-spacing: .06rem;
	color: #566c7b;
}

.site-header {
	color: #909eac;
	border-bottom: 1px solid #e5ebf1;
	background-color: $blue-gray-100;
}

.search-form {
	position: relative;

	.form-control {
		width: 100%;

		&:not(:focus) {
			border-color: transparent;
			border-bottom-color: #d7dfe8;
			background-color: transparent;
		}

		&::placeholder {
			font-size: rem(9px);
			text-transform: uppercase;
			color: #909eac;
		}

		// IE 11
		&:-ms-input-placeholder {
			height: 2.4rem;
		}

		// Edge
		&::-ms-input-placeholder {
			transform: scale(.6) translateX(-32%);
		}
	}


	.search-submit {
		font-size: 1rem;
		position: absolute;
		top: 3px;
		right: 3px;
		bottom: 3px;
		padding: .5em;

		.icon {
			display: block;
			height: 1rem;
			color: #73889d;
		}
	}
}

.menu-toggler {
	line-height: 1;
	padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
	border: $border-width solid transparent; // remove default button style
	background-color: transparent; // remove default button style

	@include font-size($navbar-toggler-font-size);
	@include border-radius($navbar-toggler-border-radius);
	@include hover-focus {
		text-decoration: none;
	}
}

.search-toggler {
	color: #73889d;
}

.call-us {
	font-size: rem(14px);
	line-height: 1;
	margin-left: 1rem;
	text-align: right;

	a {
		font-size: rem(18px);
		display: block;
		color: #00a85a;
	}

	@media (max-width: 341px) {
		display: none;
	}
}

.site-header {
	@include media-breakpoint-down(md) {
		padding: 1rem 0;

		.site-logo {
			max-width: 55px;
			margin-right: .25rem;
		}

		.site-branding-text {
			text-align: left;
		}

		.site-title {
			font-size: rem(24px);
			span {
				display: block;
			}
		}

		.site-description {
			font-size: rem(10px);
			letter-spacing: normal;
		}

		.search-form {
			width: 100%;
			margin-top: 1rem;
		}
	}
	@include media-breakpoint-up(lg) {
		.navbar {
			.nav-link {
				font-size: rem(14px);
				padding: 2.5rem .75rem;
				border-bottom: 4px solid transparent;
			}

			.nav-item.active .nav-link,
			.nav-link:hover {
				border-color: $green;
			}
		}

		.search-form {
			max-width: 120px;
		}
	}
}
